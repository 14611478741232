import { Component, Input } from '@angular/core';
import { ApiService } from './api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  subscriptionDetails = { firstName: '', lastName: '',  mobile: '',  email: '', message: '', type: 0 };
  subscription = { firstName: '', lastName: '',  mobile: '',  email: '', message: '', type: 1 };


  constructor(
    public restApi: ApiService,
    ) {

    }
  title = 'FlowerBazar';
  addSubscription() {
    this.restApi.createSubscription(this.subscription).subscribe(data => {
      console.log(data);
    });
    // console.log(this.subscriptionDetails);
  }
  sendEmail() {
    this.restApi.createSubscription(this.subscriptionDetails).subscribe(data => {
      console.log(data);
    });
  }
  refresh() {
    window.location.reload();
  }
}
