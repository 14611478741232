import { Injectable } from '@angular/core';
import { Subscription } from '../app/shared/subscription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    // Define API
    apiURL = 'https://rest.flowerbazar.ae/';

    constructor(private http: HttpClient) { }
     // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

    // HttpClient API post() method => Create employee
  createSubscription(subscription): Observable<Subscription> {
    return this.http.post<Subscription>(this.apiURL + 'api/GetGuset/AddSubscription', JSON.stringify(subscription), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }
}
